import React, { useRef } from "react"

import { navigate, useStaticQuery, graphql } from "gatsby"

import {
  Search,
  ShoppingBag,
  TextAlignJustified,
  Cross,
  ChevronRight,
} from "akar-icons"
import classNames from "classnames"
import PropTypes from "prop-types"

import { AuthContext, CartContext } from "../../store"
import Button from "../common/Button"
import Link from "../common/Link"
import CollectionDropdown from "./CollectionDropdown"
import CollectionList from "./CollectionList"
import Popover from "./Popover"
import ProductsDropdown from "./ProductsDropdown"
import ProductTypeList from "./ProductTypeList"
import UserMenu from "./UserMenu"

import "./index.scss"

const Header = ({ toggleIsCartOpen, isLogin, isShipping }) => {
  const data = useStaticQuery(graphql`
    {
      saleor {
        shop {
          name
        }
      }
    }
  `)

  const inputRef = useRef()
  const productsRef = useRef()
  const collectionRef = useRef()
  const { isAuthenticated } = React.useContext(AuthContext)
  const { lines, isPopoverOpen } = React.useContext(CartContext)

  const [isExpanded, setIsExpanded] = React.useState(false)
  const [isSearchOpen, setSearchOpen] = React.useState(false)
  const [isCollectionOpen, setCollectionOpen] = React.useState(false)
  const [isProductTypeOpen, setProductTypeOpen] = React.useState(false)
  const [isCollectionDropdownOpen, setIsCollectionDropdownOpen] =
    React.useState(false)
  const [isProductDropdownOpen, setIsProductDropdownOpen] =
    React.useState(false)
  const [isScrolled, setIsScrolled] = React.useState(false)
  const [query, setQuery] = React.useState("")
  const [isMounted, setIsMounted] = React.useState(false)

  const handleMouseMove = (e) => {
    if (productsRef.current?.contains(e.target)) {
      setProductTypeOpen(true)
      return
    }
    if (collectionRef.current?.contains(e.target)) {
      setCollectionOpen(true)
      setProductTypeOpen(false)
      return
    }
    setCollectionOpen(false)
    setProductTypeOpen(false)
  }

  React.useEffect(() => {
    setIsMounted(true)
  }, [])

  React.useEffect(() => {
    window.innerWidth > 1024 &&
      document.addEventListener("mousemove", handleMouseMove)
    window.addEventListener("scroll", handleScroll)

    return () => {
      document.removeEventListener("mousemove", handleMouseMove)
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  React.useEffect(() => {
    if (isExpanded) {
      document.querySelector("body").style.overflowY = "hidden"
    } else {
      document.querySelector("body").style.overflowY = "scroll"
    }
  })

  React.useEffect(() => {
    inputRef.current && inputRef.current.focus()
  }, [isSearchOpen])

  const handleScroll = () => {
    const { documentElement, body } = document
    const windowScrolled = body.scrollTop || documentElement.scrollTop

    setIsScrolled(windowScrolled > 0)
  }

  const handleSearchInput = (e) => {
    setQuery(e.target.value)
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    navigate(`/search/?query=${query}`)
    setIsExpanded(false)
    setQuery("")
  }

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const toggleIsSearchOpen = () => {
    setQuery("")
    setSearchOpen(!isSearchOpen)
  }

  const toggleIsCollectionOpen = () => {
    setCollectionOpen(!isCollectionOpen)
  }

  const toggleIsProductTypeOpen = () => {
    setProductTypeOpen(!isProductTypeOpen)
  }

  if (!isMounted) {
    return <div></div>
  }

  return (
    <>
      <header
        className={classNames(
          "header",
          { "is-expanded": isExpanded },
          { "is-floating": isScrolled }
        )}
      >
        <div className="header__container">
          <div className="header__start">
            <Link to="/" className="header__brand">
              {data.saleor.shop.name}
            </Link>
            <div className="header__items">
              <div
                className="header__items__item is-unselectable"
                onClick={toggleIsProductTypeOpen}
                onKeyDown={(e) =>
                  e.code === "Enter" && toggleIsProductTypeOpen()
                }
                ref={productsRef}
                tabIndex={0}
                role="button"
              >
                Products
                {isProductTypeOpen && (
                  <ProductTypeList setProductTypeOpen={setProductTypeOpen} />
                )}
              </div>
              <div
                className="header__items__item is-unselectable"
                ref={collectionRef}
                tabIndex={0}
                onClick={toggleIsCollectionOpen}
                onKeyDown={(e) =>
                  e.code === "Enter" && toggleIsCollectionOpen()
                }
                role="button"
              >
                Collections
                {isCollectionOpen && (
                  <CollectionList setCollectionOpen={setCollectionOpen} />
                )}
              </div>
              <Link
                to="/about/"
                className={`header__items__item ${
                  isSearchOpen && "header__items__item--hidden"
                } `}
              >
                About
              </Link>
              <Link
                to="/contact-us/"
                className={`header__items__item ${
                  isSearchOpen && "header__items__item--hidden"
                } `}
              >
                Contact Us
              </Link>
            </div>
          </div>
          <div className="header__end">
            <div className="header__items">
              {isSearchOpen && (
                <form
                  className="header__items__item form"
                  onSubmit={handleSearchSubmit}
                >
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Search Products"
                    value={query}
                    onChange={handleSearchInput}
                    ref={inputRef}
                  />
                </form>
              )}
              <div
                className="header__items__item"
                onClick={() => toggleIsSearchOpen()}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space")
                    toggleIsSearchOpen()
                }}
                tabIndex={0}
                role="button"
              >
                <Search color="var(--color-primary)" />
              </div>
              {!isShipping && (
                <div
                  className="header__items__item"
                  onClick={toggleIsCartOpen}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") toggleIsCartOpen()
                  }}
                  tabIndex={0}
                  role="button"
                  style={{ position: "relative" }}
                >
                  <ShoppingBag color="var(--color-primary)" />
                  {lines.length ? (
                    <span className="badge">{lines.length}</span>
                  ) : null}
                  {isPopoverOpen && <Popover />}
                </div>
              )}

              <div className="header__items__item" style={{ marginRight: 0 }}>
                {isAuthenticated ? (
                  <UserMenu />
                ) : isLogin ? (
                  <Link to="/register">
                    <Button color="primary">Register</Button>
                  </Link>
                ) : (
                  <Link to="/login">
                    <Button color="primary">Login</Button>
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div
            className="header__hamburger"
            onClick={toggleIsExpanded}
            onKeyDown={(e) => {
              if (e.key === "Enter") toggleIsExpanded()
            }}
            tabIndex={0}
            role="button"
          >
            {isExpanded ? <Cross /> : <TextAlignJustified />}
          </div>
        </div>

        {isExpanded && (
          <div className="header__mobile">
            <div className="header__mobile__icons">
              <form
                className="form"
                onSubmit={handleSearchSubmit}
                style={{
                  position: "absolute",
                  left: 25,
                  top: `${!isAuthenticated && "55px"}`,
                  bottom: `${isAuthenticated && "150px"}`,
                }}
              >
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search Products"
                  value={query}
                  onChange={handleSearchInput}
                />
              </form>

              {!isShipping && (
                <div
                  className="header__items__item"
                  onClick={() => {
                    toggleIsExpanded()
                    toggleIsCartOpen()
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      toggleIsExpanded()
                      toggleIsCartOpen()
                    }
                  }}
                  tabIndex={0}
                  role="button"
                  style={{ position: "relative" }}
                >
                  <ShoppingBag color="var(--color-primary)" />
                  {lines.length ? (
                    <span className="badge">{lines.length}</span>
                  ) : null}
                </div>
              )}

              {isAuthenticated ? (
                <div className="header__items__item">
                  <UserMenu setIsExpanded={setIsExpanded} />
                </div>
              ) : isLogin ? (
                <Link to="/register" className="login-btn">
                  <Button color="primary" onClick={() => toggleIsExpanded()}>
                    Register
                  </Button>
                </Link>
              ) : (
                <Link to="/login" className="login-btn">
                  <Button color="primary" onClick={() => toggleIsExpanded()}>
                    Login
                  </Button>
                </Link>
              )}
            </div>
            <div
              className="header__mobile__nav"
              style={{ top: `${!isAuthenticated && "80px"}` }}
            >
              <div className="header__mobile__nav__container">
                <div className="collections is-unselectable">
                  <button
                    onClick={() =>
                      setIsProductDropdownOpen(!isProductDropdownOpen)
                    }
                    className={`arrow ${
                      isProductDropdownOpen && "arrow--down"
                    } `}
                  >
                    <span>Products</span>
                    <ChevronRight size="15px" />
                  </button>
                  {isProductDropdownOpen && (
                    <div>
                      <ProductsDropdown setIsExpanded={setIsExpanded} />
                    </div>
                  )}
                </div>
                <div
                  className={`collections ${
                    isCollectionDropdownOpen && "collections--open"
                  } is-unselectable`}
                >
                  <button
                    onClick={() =>
                      setIsCollectionDropdownOpen(!isCollectionDropdownOpen)
                    }
                    className={`arrow ${
                      isCollectionDropdownOpen && "arrow--down"
                    } `}
                  >
                    <span>Collections</span>
                    <ChevronRight size="15px" />
                  </button>
                  {isCollectionDropdownOpen && (
                    <div
                      onClick={() => setIsExpanded(false)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Space")
                          setIsExpanded(false)
                      }}
                      tabIndex={0}
                      role="button"
                    >
                      <CollectionDropdown />
                    </div>
                  )}
                </div>
                <div>
                  <Link
                    to="/about/"
                    onClick={() => setIsExpanded(false)}
                    className="header__items__item"
                  >
                    About
                  </Link>
                </div>
                <div>
                  <Link
                    to="/contact-us/"
                    onClick={() => setIsExpanded(false)}
                    className="header__items__item"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
      {isPopoverOpen && (
        <div className="mobile-cart-message">
          Your item has been added to the cart successfully.
        </div>
      )}
    </>
  )
}

Header.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  isShipping: PropTypes.bool.isRequired,
  toggleIsCartOpen: PropTypes.func.isRequired,
}

export default Header
