import React from "react"

import { graphql, useStaticQuery } from "gatsby"

import PropTypes from "prop-types"

import { useClickOutside } from "../../hooks"
import "./DropdownList.scss"
import Link from "../common/Link"
import DropdownItem from "./DropdownItem"

const DropdownList = ({ items, stateUpdateFunction, isCollection }) => {
  const menuRef = React.useRef(null)

  const data = useStaticQuery(graphql`
    query categories {
      saleor {
        categories(first: 100) {
          edges {
            node {
              slug
              name
              parent {
                slug
              }
              children(first: 9) {
                edges {
                  node {
                    name
                    slug
                    children(first: 9) {
                      edges {
                        node {
                          name
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const allProducts = {
    name: "All Products",
    link: "/products/",
  }

  const categories = isCollection
    ? items
    : data.saleor.categories.edges
        .map((edge, i) => edge.node)
        .filter((n) => !n.parent)

  useClickOutside(menuRef, () => {
    stateUpdateFunction(false)
  })
  return (
    <>
      <div className="dropdown-list" ref={menuRef}>
        {" "}
        {!isCollection && (
          <DropdownItem item={allProducts} categories={categories} />
        )}
        {categories.map((category, index) => (
          <DropdownItem item={category} key={index} categories={categories} />
        ))}
      </div>
      {!isCollection && (
        <div className="dropdown-list-mobile" ref={menuRef}>
          <Link to="/products" className="dropdown-list__item">
            All Products
          </Link>
          {categories.map((category, index) => (
            <Link
              to={`/products/type/${category.slug}`}
              className="dropdown-list__item"
              key={index}
            >
              {category.name}
            </Link>
          ))}
        </div>
      )}
    </>
  )
}

DropdownList.propTypes = {
  isCollection: PropTypes.bool,
  items: PropTypes.array.isRequired,
  stateUpdateFunction: PropTypes.func.isRequired,
}

export default DropdownList
