import React from "react"

import { graphql, useStaticQuery } from "gatsby"

import PropTypes from "prop-types"

import DropdownList from "./DropdownList"

const CollectionList = ({ setCollectionOpen }) => {
  const data = useStaticQuery(graphql`
    query allCollections {
      saleor {
        collections(first: 100) {
          edges {
            node {
              id
              slug
              name
            }
          }
        }
      }
    }
  `)

  const items = data.saleor.collections.edges.map(({ node }) => {
    return {
      name: node.name,
      link: `/collection/${node.slug}/`,
    }
  })

  return (
    <DropdownList
      items={items}
      stateUpdateFunction={setCollectionOpen}
      isCollection={true}
    />
  )
}

CollectionList.propTypes = {
  setCollectionOpen: PropTypes.func.isRequired,
}

export default CollectionList
