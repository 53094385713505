import { GraphQLClient, request, gql } from "graphql-request"

import { BASE_URL } from "../utils"

export const accountAddressCreate = (input, type, token) => {
  const mutation = gql`
    mutation AccountAddressCreate(
      $input: AddressInput!
      $type: AddressTypeEnum
    ) {
      accountAddressCreate(input: $input, type: $type) {
        accountErrors {
          field
          code
          message
        }
        address {
          id
          firstName
          lastName
        }
        user {
          email
        }
      }
    }
  `
  const variables = { input, type }

  const graphQLClient = new GraphQLClient(BASE_URL, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  })
  return graphQLClient.request(mutation, variables)
}

export const accountAddressDelete = (id, token) => {
  const mutation = gql`
    mutation AccountAddressCreate($id: ID!) {
      accountAddressDelete(id: $id) {
        accountErrors {
          field
          code
          message
        }
        address {
          id
          firstName
          lastName
        }
        user {
          email
        }
      }
    }
  `
  const variables = { id }

  const graphQLClient = new GraphQLClient(BASE_URL, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  })
  return graphQLClient.request(mutation, variables)
}

export const accountAddressUpdate = (input, id, token) => {
  const mutation = gql`
    mutation AccountAddressUpdate($input: AddressInput!, $id: ID!) {
      accountAddressUpdate(input: $input, id: $id) {
        accountErrors {
          field
          code
          message
        }
        address {
          id
          firstName
          lastName
        }
        user {
          email
        }
      }
    }
  `

  const variables = { input, id }

  const graphQLClient = new GraphQLClient(BASE_URL, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  })
  return graphQLClient.request(mutation, variables)
}

export const getListOfAddress = (token) => {
  const query = gql`
    query {
      me {
        id
        email
        addresses {
          id
          city
          countryArea
          firstName
          lastName
          postalCode
          phone
          streetAddress1
          streetAddress2
        }
      }
    }
  `
  const graphQLClient = new GraphQLClient(BASE_URL, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  })
  return graphQLClient.request(query)
}

export const checkoutCreate = ({
  email,
  lines,
  shippingAddress,
  billingAddress,
}) => {
  const mutation = gql`
    mutation checkoutCreate(
      $email: String!
      $lines: [CheckoutLineInput]!
      $shippingAddress: AddressInput
      $billingAddress: AddressInput
    ) {
      checkoutCreate(
        input: {
          email: $email
          lines: $lines
          shippingAddress: $shippingAddress
          billingAddress: $billingAddress
        }
      ) {
        checkout {
          id
          discount {
            amount
          }
          voucherCode
          token
          lines {
            id
            variant {
              id
            }
          }
          totalPrice {
            gross {
              amount
              currency
            }
          }
          isShippingRequired
          availableShippingMethods {
            id
            name
            price {
              amount
              currency
            }
          }
          availablePaymentGateways {
            id
            name
            config {
              field
              value
            }
          }
        }
        checkoutErrors {
          field
          code
          message
        }
      }
    }
  `

  const variables = { email, lines, shippingAddress, billingAddress }

  if (localStorage.token) {
    const graphQLClient = new GraphQLClient(BASE_URL, {
      headers: {
        Authorization: `JWT ${localStorage.token}`,
      },
    })
    return graphQLClient.request(mutation, variables)
  } else {
    return request(BASE_URL, mutation, variables)
  }
}

export const checkoutShippingMethodUpdate = ({
  checkoutId,
  shippingMethodId,
}) => {
  const mutation = gql`
    mutation checkoutShippingMethodUpdate(
      $checkoutId: ID!
      $shippingMethodId: ID!
    ) {
      checkoutShippingMethodUpdate(
        checkoutId: $checkoutId
        shippingMethodId: $shippingMethodId
      ) {
        checkout {
          id
          voucherCode
          shippingMethod {
            name
          }
          totalPrice {
            gross {
              amount
              currency
            }
          }
          shippingPrice {
            gross {
              amount
              currency
            }
          }
        }
        checkoutErrors {
          field
          message
        }
      }
    }
  `

  const variables = {
    checkoutId,
    shippingMethodId,
  }

  if (localStorage.token) {
    const graphQLClient = new GraphQLClient(BASE_URL, {
      headers: {
        Authorization: `JWT ${localStorage.token}`,
      },
    })
    return graphQLClient.request(mutation, variables)
  } else {
    return request(BASE_URL, mutation, variables)
  }
}

export const paymentInitialize = ({ gateway, paymentData }) => {
  const mutation = gql`
    mutation paymentInitialize($gateway: String!, $paymentData: JSONString) {
      paymentInitialize(gateway: $gateway, paymentData: $paymentData) {
        initializedPayment {
          data
          name
          gateway
        }
      }
    }
  `

  const variables = {
    gateway,
    paymentData,
  }

  if (localStorage.token) {
    const graphQLClient = new GraphQLClient(BASE_URL, {
      headers: {
        Authorization: `JWT ${localStorage.token}`,
      },
    })
    return graphQLClient.request(mutation, variables)
  } else {
    return request(BASE_URL, mutation, variables)
  }
}

export const checkoutPaymentCreate = ({
  checkoutId,
  gateway,
  token,
  amount,
  returnUrl,
}) => {
  const mutation = gql`
    mutation checkoutPaymentCreate(
      $checkoutId: ID!
      $gateway: String!
      $token: String
      $amount: PositiveDecimal
    ) {
      checkoutPaymentCreate(
        checkoutId: $checkoutId
        input: { gateway: $gateway, token: $token, amount: $amount }
      ) {
        payment {
          id
          chargeStatus
        }
        paymentErrors {
          field
          message
        }
      }
    }
  `

  const variables = {
    checkoutId,
    gateway,
    token,
    amount,
  }

  if (localStorage.token) {
    const graphQLClient = new GraphQLClient(BASE_URL, {
      headers: {
        Authorization: `JWT ${localStorage.token}`,
      },
    })
    return graphQLClient.request(mutation, variables)
  } else {
    return request(BASE_URL, mutation, variables)
  }
}

export const checkoutComplete = ({ checkoutId, redirectUrl }) => {
  const mutation = gql`
    mutation checkoutComplete($checkoutId: ID!) {
      checkoutComplete(checkoutId: $checkoutId) {
        order {
          id
          status
        }
        checkoutErrors {
          field
          message
        }
      }
    }
  `

  const variables = {
    checkoutId,
  }

  if (localStorage.token) {
    const graphQLClient = new GraphQLClient(BASE_URL, {
      headers: {
        Authorization: `JWT ${localStorage.token}`,
      },
    })
    return graphQLClient.request(mutation, variables)
  } else {
    return request(BASE_URL, mutation, variables)
  }
}

export const checkoutLinesUpdate = ({ checkoutId, token, lines }) => {
  const mutation = gql`
    mutation checkoutLinesUpdate(
      $checkoutId: ID!
      $lines: [CheckoutLineInput]!
    ) {
      checkoutLinesUpdate(checkoutId: $checkoutId, lines: $lines) {
        checkout {
          id
          voucherCode
          discount {
            amount
          }
          lines {
            id
            variant {
              id
            }
          }
          totalPrice {
            gross {
              amount
              currency
            }
          }
          isShippingRequired
          availableShippingMethods {
            id
            name
            price {
              amount
              currency
            }
          }
          availablePaymentGateways {
            id
            name
            config {
              field
              value
            }
          }
        }
        checkoutErrors {
          field
          message
        }
      }
    }
  `

  const variables = { checkoutId, lines }

  if (localStorage.token) {
    const graphQLClient = new GraphQLClient(BASE_URL, {
      headers: {
        Authorization: `JWT ${localStorage.token}`,
      },
    })
    return graphQLClient.request(mutation, variables)
  } else {
    return request(BASE_URL, mutation, variables)
  }
}

export const getExistingCheckoutDetails = (perPage, after) => {
  const query = gql`
    query getExistingCheckoutDetails {
      me {
        checkout {
          id
          lines {
            id
            variant {
              id
            }
          }
        }
      }
    }
  `

  const graphQLClient = new GraphQLClient(BASE_URL, {
    headers: {
      Authorization: `JWT ${localStorage.token}`,
    },
  })
  return graphQLClient.request(query)
}

export const checkoutAddPromoCode = ({ checkoutId, promoCode }) => {
  const mutation = gql`
    mutation checkoutAddPromoCode($checkoutId: ID!, $promoCode: String!) {
      checkoutAddPromoCode(checkoutId: $checkoutId, promoCode: $promoCode) {
        checkout {
          id
          voucherCode
          discountName
          discount {
            amount
          }
          lines {
            id
            variant {
              id
            }
          }
          totalPrice {
            gross {
              amount
              currency
            }
          }
          isShippingRequired
          availableShippingMethods {
            id
            name
            price {
              amount
              currency
            }
          }
          availablePaymentGateways {
            id
            name
            config {
              field
              value
            }
          }
        }
        checkoutErrors {
          field
          message
        }
      }
    }
  `

  const variables = { checkoutId, promoCode }

  if (localStorage.token) {
    const graphQLClient = new GraphQLClient(BASE_URL, {
      headers: {
        Authorization: `JWT ${localStorage.token}`,
      },
    })
    return graphQLClient.request(mutation, variables)
  } else {
    return request(BASE_URL, mutation, variables)
  }
}

export const checkoutRemovePromoCode = ({ checkoutId, promoCode }) => {
  const mutation = gql`
    mutation checkoutRemovePromoCode($checkoutId: ID!, $promoCode: String!) {
      checkoutRemovePromoCode(checkoutId: $checkoutId, promoCode: $promoCode) {
        checkout {
          id
          voucherCode
          discountName
          discount {
            amount
          }
          lines {
            id
            variant {
              id
            }
          }
          totalPrice {
            gross {
              amount
              currency
            }
          }
          isShippingRequired
          availableShippingMethods {
            id
            name
            price {
              amount
              currency
            }
          }
          availablePaymentGateways {
            id
            name
            config {
              field
              value
            }
          }
        }
        checkoutErrors {
          field
          message
        }
      }
    }
  `

  const variables = { checkoutId, promoCode }

  if (localStorage.token) {
    const graphQLClient = new GraphQLClient(BASE_URL, {
      headers: {
        Authorization: `JWT ${localStorage.token}`,
      },
    })
    return graphQLClient.request(mutation, variables)
  } else {
    return request(BASE_URL, mutation, variables)
  }
}
