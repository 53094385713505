import React from "react"

import {
  Book,
  Person,
  SignOut,
  ArrowBackThick,
  LockOn,
  Envelope,
} from "akar-icons"
import PropTypes from "prop-types"

import { useClickOutside } from "../../hooks"
import { AuthContext, CartContext } from "../../store"
import Link from "../common/Link"

import "./UserMenu.scss"

const UserMenu = ({ setIsExpanded }) => {
  const menuRef = React.useRef(null)
  const { user, logoutUser } = React.useContext(AuthContext)
  const { clearCart } = React.useContext(CartContext)
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  useClickOutside(menuRef, () => {
    setIsMenuOpen(false)
  })

  return (
    <div className="user-menu" ref={menuRef}>
      <div
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        onKeyDown={(e) => {
          if (e.key === "Enter") setIsMenuOpen(!isMenuOpen)
        }}
        tabIndex={0}
        role="button"
      >
        <Person />
      </div>
      {isMenuOpen && (
        <div className="user-menu__items">
          <div className="user-menu__items__item email">
            <strong style={{ marginRight: 5 }}>Email: </strong>
            <span className="has-text-secondary">{user.email}</span>
          </div>
          <Link
            to="/orders/"
            className="user-menu__items__item"
            onClick={() => {
              setIsMenuOpen(false)
              setIsExpanded && setIsExpanded(false)
            }}
          >
            <span style={{ marginRight: 10 }}>
              <ArrowBackThick size="15" />
            </span>
            <span>My Orders</span>
          </Link>
          <Link
            to="/manage-address/"
            className="user-menu__items__item"
            onClick={() => {
              setIsMenuOpen(false)
              setIsExpanded && setIsExpanded(false)
            }}
          >
            <span style={{ marginRight: 10 }}>
              <Book size={15} />
            </span>
            <span>Manage Address</span>
          </Link>
          <Link
            to="/change-password/"
            className="user-menu__items__item"
            onClick={() => {
              setIsMenuOpen(false)
              setIsExpanded && setIsExpanded(false)
            }}
          >
            <span style={{ marginRight: 10 }}>
              <LockOn size="15" />
            </span>
            <span>Change Password</span>
          </Link>
          <Link
            to="/change-email/"
            className="user-menu__items__item"
            onClick={() => {
              setIsMenuOpen(false)
              setIsExpanded && setIsExpanded(false)
            }}
          >
            <span style={{ marginRight: 10 }}>
              <Envelope size="15" />
            </span>
            <span>Change Email</span>
          </Link>
          <div
            className="user-menu__items__item"
            onClick={() => {
              setIsMenuOpen(false)
              logoutUser()
              clearCart()
              setIsExpanded && setIsExpanded(false)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setIsMenuOpen(false)
                logoutUser()
                clearCart()
              }
            }}
            tabIndex={0}
            role="button"
          >
            <span style={{ marginRight: 10 }}>
              <SignOut size="17" />
            </span>
            <span>Logout</span>
          </div>
        </div>
      )}
    </div>
  )
}

UserMenu.propTypes = {
  setIsExpanded: PropTypes.func.isRequired,
}

export default UserMenu
