import { GraphQLClient, gql } from "graphql-request"

import { BASE_URL } from "../utils"

export const fetchOrders = (perPage, after) => {
  const query = gql`
    query OrdersByUser($perPage: Int!, $after: String) {
      me {
        id
        orders(first: $perPage, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              number
              created
              isPaid
              statusDisplay
              trackingClientId
              status
              paymentStatus
              paymentStatusDisplay
              customerNote
              fulfillments {
                trackingNumber
                status
                statusDisplay
              }
              lines {
                quantity
                productName
                unitPrice {
                  net {
                    currency
                    amount
                  }
                }
                totalPrice {
                  net {
                    currency
                    amount
                  }
                }
                variant {
                  weight {
                    unit
                    value
                  }
                  product {
                    category {
                      name
                    }
                    slug
                  }
                  name
                }
                thumbnail {
                  url
                  alt
                }
              }
              payments {
                total {
                  amount
                  currency
                }
              }
            }
          }
        }
      }
    }
  `

  const variables = { perPage, after }

  const graphQLClient = new GraphQLClient(BASE_URL, {
    headers: {
      Authorization: `JWT ${localStorage.token}`,
    },
  })
  return graphQLClient.request(query, variables)
}
