import React, { useState } from "react"

import { graphql, useStaticQuery } from "gatsby"

import { ChevronRight } from "akar-icons"
import PropTypes from "prop-types"

import Link from "../common/Link"
import "./ProductsDropdown.scss"

const ProductsDropdown = ({ setIsExpanded }) => {
  const data = useStaticQuery(graphql`
    query productCategories {
      saleor {
        categories(first: 100) {
          edges {
            node {
              slug
              name
              parent {
                slug
              }
              children(first: 9) {
                edges {
                  node {
                    name
                    slug
                    children(first: 9) {
                      edges {
                        node {
                          name
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [openedCategory, setOpenedCategory] = useState(null)
  const [openedSubCategory, setOpenedSubCategory] = useState(null)

  const categories = data.saleor.categories.edges
    .map((edge, i) => edge.node)
    .filter((n) => !n.parent)

  return (
    <div className="products-dropdown">
      <Link
        to="/products"
        className="all-products"
        onClick={() => setIsExpanded(false)}
      >
        All Products
      </Link>
      {categories.map((category, i) => {
        const subCategories = category.children?.edges.map((edge) => edge.node)
        return (
          <div className="products-dropdown__item" key={i}>
            <div className="products-dropdown__item__row">
              <Link
                to={`/products/type/${category.slug}`}
                onClick={() => setIsExpanded(false)}
                className=""
              >
                {category.name}
              </Link>
              {subCategories?.length > 0 && (
                <button
                  className={`category-arrow ${
                    openedCategory === i && "category-arrow--down"
                  } `}
                  onClick={() =>
                    openedCategory === i
                      ? setOpenedCategory(null)
                      : setOpenedCategory(i)
                  }
                >
                  <ChevronRight size="10" color="#303546" />
                </button>
              )}
            </div>
            {openedCategory === i &&
              subCategories.map((subCategory, idx) => {
                const nestedSubCategories = subCategory.children?.edges.map(
                  (edge) => edge.node
                )
                return (
                  <div
                    className="products-dropdown__item__nested-row"
                    key={idx}
                  >
                    <Link
                      to={`/products/type/${subCategory.slug}`}
                      className=""
                      onClick={() => setIsExpanded(false)}
                    >
                      {subCategory.name}
                    </Link>
                    {nestedSubCategories?.length > 0 && (
                      <button
                        className={`category-arrow ${
                          openedSubCategory === idx && "category-arrow--down"
                        } `}
                        onClick={() => setOpenedSubCategory(idx)}
                      >
                        <ChevronRight size="10" color="#303546" />
                      </button>
                    )}
                    {openedSubCategory === idx &&
                      nestedSubCategories.map((nestedSubCategory, index) => (
                        <Link
                          to={`/products/type/${nestedSubCategory.slug}`}
                          onClick={() => setIsExpanded(false)}
                          key={index}
                        >
                          {nestedSubCategory.name}
                        </Link>
                      ))}
                  </div>
                )
              })}
          </div>
        )
      })}
    </div>
  )
}

ProductsDropdown.propTypes = {
  setIsExpanded: PropTypes.func.isRequired,
}

export default ProductsDropdown
