import { gql, GraphQLClient } from "graphql-request"

import { BASE_URL } from "../utils"

const client = new GraphQLClient(BASE_URL)

export const setAuthToken = (token) => {
  client.setHeader("Authorization", `JWT ${token}`)
}

export const removeAuthToken = () => {
  client.setHeader("Authorization", "")
}

if (typeof window !== "undefined") {
  setAuthToken(localStorage.token)
}

export const accountRegister = ({ email, password }) => {
  const mutation = gql`
    mutation accountRegister($email: String!, $password: String!) {
      accountRegister(input: { email: $email, password: $password }) {
        accountErrors {
          field
          code
          message
        }
        user {
          email
          isActive
        }
      }
    }
  `

  const variables = { email, password }

  return client.request(mutation, variables)
}

export const tokenCreate = ({ email, password }) => {
  const mutation = gql`
    mutation tokenCreate($email: String!, $password: String!) {
      tokenCreate(email: $email, password: $password) {
        token
        refreshToken
        csrfToken
        user {
          email
        }
        accountErrors {
          field
          message
          code
        }
      }
    }
  `

  const variables = { email, password }

  return client.request(mutation, variables)
}

export const passwordChange = ({ oldPassword, newPassword }) => {
  const mutation = gql`
    mutation passwordChange($oldPassword: String!, $newPassword: String!) {
      passwordChange(oldPassword: $oldPassword, newPassword: $newPassword) {
        accountErrors {
          field
          code
          message
        }
      }
    }
  `

  const variables = { oldPassword, newPassword }

  return client.request(mutation, variables)
}

export const requestPasswordReset = ({ email, redirectUrl }) => {
  const mutation = gql`
    mutation requestPasswordReset($email: String!, $redirectUrl: String!) {
      requestPasswordReset(email: $email, redirectUrl: $redirectUrl) {
        accountErrors {
          field
          code
          message
        }
      }
    }
  `

  const variables = { email, redirectUrl }

  return client.request(mutation, variables)
}

export const setPassword = ({ token, email, password }) => {
  const mutation = gql`
    mutation setPassword($token: String!, $email: String!, $password: String!) {
      setPassword(token: $token, email: $email, password: $password) {
        accountErrors {
          field
          code
          message
        }
      }
    }
  `

  const variables = { token, email, password }

  return client.request(mutation, variables)
}

export const requestEmailChange = ({ newEmail, password, redirectUrl }) => {
  const mutation = gql`
    mutation requestEmailChange(
      $newEmail: String!
      $password: String!
      $redirectUrl: String!
    ) {
      requestEmailChange(
        newEmail: $newEmail
        password: $password
        redirectUrl: $redirectUrl
      ) {
        accountErrors {
          field
          code
          message
        }
      }
    }
  `

  const variables = { newEmail, password, redirectUrl }

  return client.request(mutation, variables)
}

export const confirmEmailChange = (token) => {
  const mutation = gql`
    mutation confirmEmailChange($token: String!) {
      confirmEmailChange(token: $token) {
        accountErrors {
          field
          code
          message
        }
        user {
          email
        }
      }
    }
  `

  const variables = { token }

  return client.request(mutation, variables)
}
