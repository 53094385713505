import React from "react"

import { ChevronRight } from "akar-icons"
import PropTypes from "prop-types"

import { useClickOutside } from "../../hooks"
import Link from "../common/Link"

const DropdownItem = ({ item, stateUpdateFunction, categories }) => {
  const menuRef = React.useRef(null)

  const filteredCategory = categories.filter(
    (category) => category.name === item.name
  )
  const subCategories = filteredCategory[0]?.children?.edges.map(
    (edge) => edge.node
  )

  useClickOutside(menuRef, () => {
    stateUpdateFunction(false)
  })

  return (
    <div className="category-container">
      <Link
        to={item.link || `/products/type/${item.slug}`}
        className="dropdown-list__item"
      >
        {item.name}
      </Link>
      {subCategories?.length > 0 && <ChevronRight size="10" color="#303546" />}
      {subCategories && (
        <div className="sub-categories">
          {subCategories.map((subCategory, i) => {
            return (
              <div className="subcategory-container" key={i}>
                <Link
                  to={`/products/type/${subCategory.slug}`}
                  className="dropdown-list__item"
                >
                  {subCategory.name}
                </Link>
                {subCategory.children.edges.length > 0 && (
                  <ChevronRight size="10" color="#303546" />
                )}
                {subCategory.children.edges.length > 0 && (
                  <div className="nested-sub-categories">
                    {subCategory?.children?.edges.map(({ node }, i) => (
                      <Link
                        to={`/products/type/${node.slug}`}
                        className="dropdown-list__item"
                        key={i}
                      >
                        {node.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

DropdownItem.propTypes = {
  item: PropTypes.object.isRequired,
  stateUpdateFunction: PropTypes.func.isRequired,
  categories: PropTypes.object.isRequired,
}

export default DropdownItem
