import React, { useEffect } from "react"

import PropTypes from "prop-types"

import { getExistingCheckoutDetails } from "../graphql"
import { accountAddressCreate } from "../graphql/checkout"

const initialState = {
  isLoading: false,
  billingAddress: {
    city: "",
    country: "IN",
    countryArea: "",
    firstName: "",
    lastName: "",
    postalCode: "",
    phone: "",
    streetAddress1: "",
    streetAddress2: "",
  },
  email: "",
  lines: [],
  shippingAddress: {
    city: "",
    country: "IN",
    countryArea: "",
    firstName: "",
    lastName: "",
    postalCode: "",
    phone: "",
    streetAddress1: "",
    streetAddress2: "",
  },
  availablePaymentGateways: [],
  availableShippingMethods: [],
  checkoutId: "",
  discount: "",
  checkoutLines: [],
  selectedShippingMethod: {},
  selectedPaymentGateway: {},
  voucherCode: "",
}

export const CartContext = React.createContext(initialState)

const CartReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADER":
      return {
        ...state,
        isLoading: action.payload,
      }
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case "SET_EMAIL":
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case "SET_SHIPPING_ADDRESS":
      return {
        ...state,
        isLoading: false,
        shippingAddress: action.payload,
      }
    case "SET_BILLING_ADDRESS":
      return {
        ...state,
        billingAddress: action.payload,
        isLoading: false,
      }
    case "ADD_ITEMS":
      return {
        ...state,
        isLoading: false,
        lines: action.payload,
      }
    case "UPDATE_QUANTITY":
      return {
        ...state,
        isLoading: false,
        lines: state.lines.map((item) => {
          if (item.variantId === action.payload.variantId) {
            item.quantity = action.payload.quantity
          }
          return item
        }),
      }
    case "REMOVE_ITEM":
      return {
        ...state,
        isLoading: false,
        lines: state.lines.filter((item) => item.variantId !== action.payload),
      }
    case "CHECKOUT": {
      return {
        ...state,
        id: action.payload,
        isLoading: false,
      }
    }
    case "SET_CHECKOUT_OBJECT": {
      return {
        ...state,
        availablePaymentGateways: action.payload.availablePaymentGateways,
        availableShippingMethods: action.payload.availableShippingMethods,
        checkoutId: action.payload.id,
        checkoutLines: action.payload.lines.map((line) => ({
          quantity: 0,
          variantId: line.variant.id,
        })),
        discount: action.payload.discount.amount,
        voucherCode: action.payload.voucherCode,
      }
    }
    case "SET_SHIPPING_METHOD": {
      return {
        ...state,
        selectedShippingMethod: action.payload,
      }
    }
    case "SET_PAYMENT_GATEWAY": {
      return {
        ...state,
        selectedPaymentGateway: action.payload,
      }
    }
    case "CLEAR_CART": {
      return {
        ...state,
        checkoutId: "",
        lines: action.payload,
      }
    }
    case "SET_EXISTING_CHECKOUT": {
      return {
        ...state,
        checkoutId: action.payload.id,
        checkoutLines: action.payload.lines,
      }
    }
    default:
      return state
  }
}

export const CartProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(CartReducer, initialState)
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)

  function setLoader(isLoading) {
    dispatch({ payload: isLoading, type: "SET_LOADER" })
  }

  function continueCart(items) {
    dispatch({ payload: items, type: "ADD_ITEMS" })
  }

  function addItem(variantId, quantity, name, price, variantName) {
    setIsPopoverOpen(true)
    setTimeout(() => setIsPopoverOpen(false), 3000)
    dispatch({ payload: true, type: "SET_LOADER" })

    const lineItem = { name, price, quantity, variantId, variantName }

    let isAlreadyInCart = false
    const updatedLines = state.lines.map((item) => {
      if (item.variantId === variantId) {
        item.quantity += parseInt(quantity)
        isAlreadyInCart = true
      }
      return item
    })

    sessionStorage.setItem(
      "lines",
      JSON.stringify(
        isAlreadyInCart ? updatedLines : [...state.lines, lineItem]
      )
    )
    dispatch({
      payload: isAlreadyInCart ? updatedLines : [...state.lines, lineItem],
      type: "ADD_ITEMS",
    })
  }

  function updateQuantity(variantId, quantity) {
    const updatedItem = { quantity, variantId }

    if (quantity <= 0) {
      const storedItems = JSON.parse(sessionStorage.getItem("lines"))

      sessionStorage.setItem(
        "lines",
        JSON.stringify(
          storedItems.filter((item) => item.variantId !== variantId)
        )
      )
      dispatch({ payload: variantId, type: "REMOVE_ITEM" })
    } else {
      dispatch({ payload: updatedItem, type: "UPDATE_QUANTITY" })
    }
  }

  function removeItem(variantId, quantity) {
    dispatch({ payload: variantId, type: "REMOVE_ITEM" })
  }

  function setEmail(user) {
    dispatch({ payload: true, type: "SET_LOADER" })
    dispatch({ payload: user.email, type: "SET_EMAIL" })
  }

  function setCheckoutCreateObject(checkoutCreateObject) {
    dispatch({ payload: checkoutCreateObject, type: "SET_CHECKOUT_OBJECT" })
  }

  function setSelectedShippingMethod(shippingMethod) {
    dispatch({ payload: shippingMethod, type: "SET_SHIPPING_METHOD" })
  }

  function setSelectedPaymentGateway(paymentGateway) {
    dispatch({ payload: paymentGateway, type: "SET_PAYMENT_GATEWAY" })
  }

  function setAddress(
    shippingAddress,
    billingAddress,
    isAddNewShipping,
    isAddNewBilling
  ) {
    if (shippingAddress) {
      if (isAddNewShipping) {
        accountAddressCreate(shippingAddress, "SHIPPING", localStorage.token)
      }
      dispatch({ payload: shippingAddress, type: "SET_SHIPPING_ADDRESS" })
    }
    if (billingAddress) {
      if (isAddNewBilling) {
        accountAddressCreate(billingAddress, "BILLING", localStorage.token)
      }
      dispatch({ payload: billingAddress, type: "SET_BILLING_ADDRESS" })
    }
  }

  function clearCart() {
    sessionStorage.removeItem("lines")
    dispatch({ payload: [], type: "CLEAR_CART" })
    dispatch({
      payload: initialState.shippingAddress,
      type: "SET_SHIPPING_ADDRESS",
    })
    dispatch({
      payload: initialState.billingAddress,
      type: "SET_BILLING_ADDRESS",
    })
  }

  useEffect(() => {
    localStorage.token &&
      getExistingCheckoutDetails().then((res) => {
        const id = res.me.checkout?.id
        const lines = res.me.checkout?.lines.map((line) => ({
          quantity: 0,
          variantId: line.variant.id,
        }))

        dispatch({ payload: { id, lines }, type: "SET_EXISTING_CHECKOUT" })
      })
  }, [])

  return (
    <CartContext.Provider
      value={{
        ...state,
        addItem,
        clearCart,
        continueCart,
        isPopoverOpen,
        removeItem,
        setEmail,
        setIsPopoverOpen,
        setLoader,
        setCheckoutCreateObject,
        updateQuantity,
        setSelectedShippingMethod,
        setSelectedPaymentGateway,
        setAddress,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
