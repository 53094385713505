import { request, gql, GraphQLClient } from "graphql-request"

import { BASE_URL } from "../utils"

export const fetchPage = (id) => {
  const query = gql`
    query fetchPage($id: ID!) {
      page(id: $id) {
        id
        title
        slug
        seoTitle
        seoDescription
        content
        metadata {
          key
          value
        }
      }
    }
  `

  const variables = { id }

  return request(BASE_URL, query, variables)
}

export const updatePage = (token, id, title, content) => {
  const mutation = gql`
    mutation updatePage($id: ID!, $title: String!, $content: String!) {
      pageUpdate(id: $id, input: { content: $content, title: $title }) {
        page {
          content
          title
        }
      }
    }
  `

  const graphQLClient = new GraphQLClient(BASE_URL, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  })

  const variables = { id, title, content }

  return graphQLClient.request(mutation, variables)
}
