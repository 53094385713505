import { request, gql } from "graphql-request"

import { BASE_URL } from "../utils"

export const fetchProduct = (slug) => {
  const query = gql`
    query fetchProduct($slug: String!) {
      product(slug: $slug) {
        id
        slug
        name
        seoTitle
        seoDescription
        descriptionJson
        category {
          name
        }
        variants {
          name
          id
          pricing {
            price {
              gross {
                amount
                currency
              }
            }
          }
        }
        pricing {
          discount {
            gross {
              amount
              currency
            }
          }
          onSale
          priceRange {
            start {
              net {
                amount
              }
            }
            stop {
              net {
                amount
              }
            }
          }
        }
        images {
          url
          alt
        }
      }
    }
  `

  const variables = { slug }

  return request(BASE_URL, query, variables)
}

export const fetchProducts = (first = 10) => {
  const query = gql`
    query fetchProducts($first: Int!) {
      products(first: $first, filter: { stockAvailability: IN_STOCK }) {
        edges {
          node {
            id
            slug
            name
            descriptionJson
            category {
              name
            }
            variants {
              name
              id
              pricing {
                price {
                  gross {
                    amount
                    currency
                  }
                }
              }
            }
            pricing {
              discount {
                gross {
                  amount
                  currency
                }
              }
              onSale
              priceRange {
                start {
                  net {
                    amount
                  }
                }
                stop {
                  net {
                    amount
                  }
                }
              }
            }
            thumbnail {
              url
              alt
            }
          }
        }
      }
    }
  `

  const variables = { first }

  return request(BASE_URL, query, variables)
}

export const searchProducts = async (first = 10, search) => {
  const query = gql`
    query searchProducts($first: Int!, $search: String!) {
      products(
        first: $first
        filter: { search: $search, stockAvailability: IN_STOCK }
      ) {
        edges {
          node {
            id
            slug
            name
            seoTitle
            seoDescription
            descriptionJson
            visibleInListings
            isAvailable
            isAvailableForPurchase
            category {
              name
            }
            variants {
              name
              id
              pricing {
                price {
                  gross {
                    amount
                    currency
                  }
                }
              }
            }
            pricing {
              discount {
                gross {
                  amount
                  currency
                }
              }
              onSale
              priceRange {
                start {
                  net {
                    amount
                  }
                }
                stop {
                  net {
                    amount
                  }
                }
              }
            }
            thumbnail {
              url
              alt
            }
            images {
              url
              alt
            }
          }
        }
      }
    }
  `

  const variables = { first, search }

  const response = await request(BASE_URL, query, variables)

  return response
}
