import React from "react"

import { Cross, CirclePlus, CircleMinus, Air } from "akar-icons"
import PropType from "prop-types"

import { CartContext } from "../../store"
import Button from "../common/Button"
import Link from "../common/Link"

import "./Cart.scss"

const Cart = ({ toggleIsCartOpen }) => {
  const { lines, updateQuantity } = React.useContext(CartContext)

  const handleKeyDown = React.useCallback(
    (e) => {
      if (e.key === "Escape") {
        toggleIsCartOpen()
      }
    },
    [toggleIsCartOpen]
  )

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <aside className="cart">
      <div
        className="cart__background"
        onClick={toggleIsCartOpen}
        onKeyDown={(e) => {
          if (e.key === "Enter") toggleIsCartOpen()
        }}
        tabIndex={0}
        role="button"
      />
      <div className="cart__sidebar">
        <div
          className="cart__sidebar__close"
          onClick={toggleIsCartOpen}
          onKeyDown={(e) => {
            if (e.key === "Enter") toggleIsCartOpen()
          }}
          tabIndex={0}
          role="button"
        >
          <Cross />
        </div>
        <div className="cart__sidebar__title">Cart</div>
        {lines.length ? (
          <>
            <div className="cart__sidebar__products">
              {lines.map((product) => (
                <div key={product.variantId} className="cart__sidebar__product">
                  <div className="cart__sidebar__product__name">
                    {product.name}{" "}
                    {product.variantName && `(${product.variantName})`}
                  </div>
                  <div className="cart__sidebar__product__quantity">
                    <CircleMinus
                      size="17"
                      className="has-cursor-pointer"
                      onClick={() =>
                        updateQuantity(product.variantId, product.quantity - 1)
                      }
                    />
                    {product.quantity}
                    <CirclePlus
                      size="17"
                      className="has-cursor-pointer"
                      onClick={() =>
                        updateQuantity(product.variantId, product.quantity + 1)
                      }
                    />
                  </div>
                  <div className="cart__sidebar__product__price">
                    ₹ {product.price * product.quantity}
                  </div>
                </div>
              ))}
            </div>
            <div className="cart__sidebar__checkout">
              <Link to="/checkout/" onClick={toggleIsCartOpen}>
                <Button color="primary" isBlock isLarge>
                  Checkout
                </Button>
              </Link>
            </div>
          </>
        ) : (
          <div className="cart__sidebar__no-products">
            <Air style={{ margin: "auto" }} size={32} />
            <br />
            <p>No Products</p>
          </div>
        )}
      </div>
    </aside>
  )
}

Cart.propTypes = {
  toggleIsCartOpen: PropType.func.isRequired,
}

export default Cart
