import React from "react"

import { Link } from "gatsby"

import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { WhatsappFill } from "akar-icons"
import PropTypes from "prop-types"

import Cart from "../components/cart/Cart"
import Toast from "../components/common/Toast"
import Footer from "../components/footer"
import Header from "../components/header"
import { AuthContext, CartContext } from "../store"
import { FALLBACK_FONT_FAMILY } from "../utils/constants"

import "../assets/scss/main.scss"

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

const Layout = ({ location, children }) => {
  const { isAuthenticated, continueSession } = React.useContext(AuthContext)
  const { lines, continueCart } = React.useContext(CartContext)
  const [isCartOpen, setIsCartOpen] = React.useState(false)

  React.useEffect(() => {
    if (localStorage && sessionStorage) {
      if (localStorage.token && !isAuthenticated && continueSession) {
        continueSession({
          csrfToken: localStorage.csrfToken,
          email: localStorage.email,
          refreshToken: localStorage.refreshToken,
          token: localStorage.token,
        })
      }
      if (
        sessionStorage.lines &&
        continueCart &&
        lines.length !== JSON.parse(sessionStorage.lines).length
      ) {
        continueCart(JSON.parse(sessionStorage.lines))
      }
    }
  }, [continueSession, continueCart, isAuthenticated, lines])

  React.useEffect(() => {
    if (isCartOpen === true) {
      document.querySelector("body").classList.add("modal-open")
    } else {
      document.querySelector("body").classList.remove("modal-open")
    }
  }, [isCartOpen])

  React.useEffect(() => {
    document.documentElement.style.setProperty(
      "--font-primary",
      process.env.GATSBY_APP_FONT_FAMILY || FALLBACK_FONT_FAMILY
    )
  }, [])

  const toggleIsCartOpen = () => {
    setIsCartOpen(!isCartOpen)
  }

  if (location.pathname.includes("/cms")) {
    return <main>{children}</main>
  } else {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occurred"}>
        <main>
          <Header
            toggleIsCartOpen={toggleIsCartOpen}
            isLogin={
              location.pathname.includes("login") ||
              location.pathname.includes("forgot-password")
            }
            isShipping={
              location.pathname.includes("shipping") ||
              location.pathname.includes("payment")
            }
          />
          <Toast />
          {children}
          {process.env.GATSBY_APP_CONTACT_NO && (
            <Link
              to={`https://api.whatsapp.com/send/?phone=${process.env.GATSBY_APP_CONTACT_NO}&text`}
              className="whatsapp-chat"
              target="_blank"
              title="click to chat"
            >
              <WhatsappFill
                strokeWidth={2}
                size={60}
                color={"var(--color-lightest)"}
              />
            </Link>
          )}
          <Footer />
          {isCartOpen && <Cart toggleIsCartOpen={toggleIsCartOpen} />}
        </main>
      </Sentry.ErrorBoundary>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
