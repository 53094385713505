import React from "react"

import { graphql, useStaticQuery } from "gatsby"

import PropTypes from "prop-types"

import Link from "../common/Link"
import "./CollectionDropdown.scss"

const CollectionDropdown = ({ item, stateUpdateFunction, categories }) => {
  const data = useStaticQuery(graphql`
    query collections {
      saleor {
        collections(first: 100) {
          edges {
            node {
              id
              slug
              name
            }
          }
        }
      }
    }
  `)

  const items = data.saleor.collections.edges.map(({ node }) => {
    return {
      name: node.name,
      link: `/collection/${node.slug}/`,
    }
  })

  return (
    <div className="collection-dropdown">
      {items.map((item, i) => (
        <Link to={item.link} className="collection-dropdown__item" key={i}>
          {item.name}
        </Link>
      ))}
    </div>
  )
}

CollectionDropdown.propTypes = {
  item: PropTypes.object.isRequired,
  stateUpdateFunction: PropTypes.func.isRequired,
  categories: PropTypes.object.isRequired,
}

export default CollectionDropdown
