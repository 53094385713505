import React from "react"

import "./Popover.scss"

const Popover = () => {
  return (
    <div className="popover">
      <div className="popover__triangle" />
      Item has been added to the cart successfully.
    </div>
  )
}

export default Popover
