import React, { useContext } from "react"

import classNames from "classnames"
import PropTypes from "prop-types"

import "./Button.scss"
import { AuthContext } from "../../store/auth"

const Button = ({
  children,
  isBlock,
  isLarge,
  onClick,
  type,
  color,
  disabled,
  ...remainingProps
}) => {
  const { isLoading } = useContext(AuthContext)

  return (
    <button
      type={type}
      className={classNames(
        "button",
        `button--${color}`,
        { "button--block": isBlock },
        { "button--large": isLarge },
        { "button--disabled": isLoading || disabled }
      )}
      onClick={onClick}
      {...remainingProps}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  isBlock: PropTypes.bool,
  isLarge: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
}

Button.defaultProps = {
  color: "primary",
  isBlock: false,
  isLarge: false,
  type: "button",
}

export default Button
