import React from "react"

import { navigate } from "gatsby"

import NProgress from "nprogress"
import PropTypes from "prop-types"

import {
  TYPE_ERROR,
  toastMessage,
  TYPE_SUCCESS,
} from "../components/common/Toast"
import {
  accountRegister,
  tokenCreate,
  passwordChange,
  setAuthToken,
  removeAuthToken,
  requestPasswordReset,
  setPassword,
  requestEmailChange,
  confirmEmailChange,
} from "../graphql/user"
import {
  REDIRECT_URL_FORGOT_PASS,
  REDIRECT_URL_CHANGE_EMAIL_CONFIRM,
} from "../utils/constants"

const initialState = {
  error: {},
  isAuthenticated: false,
  isLoading: false,
  user: {
    avatar: {},
    firstName: "",
    id: "",
    isLoggedIn: false,
    lastName: "",
    token: "",
    username: "",
    email: "",
  },
}

export const AuthContext = React.createContext(initialState)

const AuthReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADER":
      return {
        ...state,
        isLoading: action.payload,
      }
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case "CONTINUE_SESSION":
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      }
    case "LOGIN_USER":
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      }
    case "LOGOUT_USER":
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        user: {},
      }
    case "UPDATE_EMAIL":
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload,
        },
      }
    default:
      return state
  }
}

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(AuthReducer, initialState)

  function setLoader(isLoading) {
    dispatch({ payload: isLoading, type: "SET_LOADER" })
  }

  function continueSession(user) {
    dispatch({ payload: user, type: "LOGIN_USER" })
  }

  function loginUser(user) {
    setLoader(true)
    dispatch({ payload: true, type: "SET_LOADER" })
    NProgress.start()

    tokenCreate(user)
      .then((res) => {
        setLoader(false)
        NProgress.done()
        const { tokenCreate } = res

        if (tokenCreate.accountErrors.length === 0) {
          setAuthToken(tokenCreate.token)
          localStorage.setItem("csrfToken", tokenCreate.csrfToken)
          localStorage.setItem("email", tokenCreate.user.email)
          localStorage.setItem("refreshToken", tokenCreate.refreshToken)
          localStorage.setItem("token", tokenCreate.token)

          const userInformation = {
            csrfToken: tokenCreate.csrfToken,
            email: tokenCreate.user.email,
            refreshToken: tokenCreate.refreshToken,
            token: tokenCreate.token,
          }

          dispatch({ payload: userInformation, type: "LOGIN_USER" })
          navigate("/")
        } else {
          const errorMessage = tokenCreate.accountErrors[0].message
          toastMessage(errorMessage, TYPE_ERROR)
        }
      })
      .catch((error) => {
        setLoader(false)
        NProgress.done()
        console.log(error)
      })
  }

  function registerUser(user) {
    setLoader(true)
    dispatch({ payload: true, type: "SET_LOADER" })
    NProgress.start()

    accountRegister(user)
      .then((res) => {
        setLoader(false)
        if (res.accountRegister.accountErrors.length > 0) {
          toastMessage(res.accountRegister.accountErrors[0].message, TYPE_ERROR)
          NProgress.done()
        } else {
          loginUser(user)
        }
      })
      .catch((error) => {
        setLoader(false)
        NProgress.done()
        console.log(error)
      })
  }

  function logoutUser() {
    sessionStorage.clear()
    removeAuthToken()
    dispatch({ type: "LOGOUT_USER" })
    localStorage.clear()
    navigate("/login")
  }

  function changePassword(credentials) {
    setLoader(true)
    dispatch({ payload: true, type: "SET_LOADER" })
    NProgress.start()

    passwordChange(credentials)
      .then((res) => {
        setLoader(false)
        NProgress.done()
        if (res.passwordChange.accountErrors.length === 0) {
          toastMessage("Password changed successfully!", TYPE_SUCCESS)
          navigate("/")
        } else {
          const errorMessage = res.passwordChange.accountErrors[0].message
          toastMessage(errorMessage, TYPE_ERROR)
        }
      })
      .catch((error) => {
        setLoader(false)
        NProgress.done()
        console.log(error)
      })
  }

  function requestPasswordResetEmail(email) {
    setLoader(true)
    dispatch({ payload: true, type: "SET_LOADER" })
    NProgress.start()

    const credentials = {
      email,
      redirectUrl: REDIRECT_URL_FORGOT_PASS,
    }

    requestPasswordReset(credentials)
      .then((res) => {
        setLoader(false)
        NProgress.done()
        if (res.requestPasswordReset.accountErrors.length === 0) {
          navigate("/reset-password-confirm", {
            state: { email },
          })
        } else {
          const errorMessage = res.requestPasswordReset.accountErrors[0].message
          toastMessage(errorMessage, TYPE_ERROR)
        }
      })
      .catch((error) => {
        setLoader(false)
        NProgress.done()
        console.log(error)
      })
  }

  function resetPassword(credentials) {
    setLoader(true)
    dispatch({ payload: true, type: "SET_LOADER" })
    NProgress.start()

    setPassword(credentials)
      .then((res) => {
        setLoader(false)
        NProgress.done()
        if (res.setPassword.accountErrors.length === 0) {
          navigate("/password-change-success", {
            state: { email: credentials.email },
          })
        } else {
          const errorMessage = res.setPassword.accountErrors[0].message
          toastMessage(errorMessage, TYPE_ERROR)
        }
      })
      .catch((error) => {
        setLoader(false)
        NProgress.done()
        console.log(error)
      })
  }

  function changeEmail(newEmail, password) {
    setLoader(true)
    dispatch({ payload: true, type: "SET_LOADER" })
    NProgress.start()

    const credentials = {
      newEmail,
      password,
      redirectUrl: REDIRECT_URL_CHANGE_EMAIL_CONFIRM,
    }

    requestEmailChange(credentials)
      .then((res) => {
        setLoader(false)
        NProgress.done()

        if (res.requestEmailChange.accountErrors.length === 0) {
          navigate("/change-email-message", {
            state: { email: newEmail },
          })
        } else {
          const errorMessage = res.requestEmailChange.accountErrors[0].message
          toastMessage(errorMessage, TYPE_ERROR)
        }
      })
      .catch((error) => {
        setLoader(false)
        NProgress.done()
        console.log(error)
      })
  }

  function confirmEmailUpdate(token) {
    setLoader(true)
    const tokenFromLocalStorage = !!localStorage.getItem("token")
    if (!tokenFromLocalStorage) {
      logoutUser()
      return
    }
    dispatch({ payload: true, type: "SET_LOADER" })
    NProgress.start()

    confirmEmailChange(token)
      .then((res) => {
        setLoader(false)
        NProgress.done()
        if (res.confirmEmailChange.accountErrors.length === 0) {
          toastMessage(
            "Email changed successfully. Please login again with the new email",
            TYPE_SUCCESS
          )
          logoutUser()
        } else {
          const errorMessage = res.confirmEmailChange.accountErrors[0].message
          toastMessage(errorMessage, TYPE_ERROR)
        }
      })
      .catch((error) => {
        setLoader(false)
        NProgress.done()
        console.log(error)
      })
  }

  return (
    <AuthContext.Provider
      value={{
        changeEmail,
        confirmEmailUpdate,
        changePassword,
        continueSession,
        error: state.error,
        isAuthenticated: state.isAuthenticated,
        isLoading: state.isLoading,
        loginUser,
        logoutUser,
        requestPasswordResetEmail,
        registerUser,
        resetPassword,
        setLoader,
        user: state.user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
