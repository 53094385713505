import draftToHtml from "draftjs-to-html"

export const BASE_URL = `https://ecommapi.inkoop.io/graphql/`
export const SUPERVISOR_BASE_URL = `https://ik-supervisor.herokuapp.com`
export const STORE_ID = `a`

export const capitalize = (str) => {
  return `${str[0].toUpperCase()}${str.slice(1)}`
}

export const CONTACT_BACKGROUND_IMAGE_URL =
  "https://images.unsplash.com/photo-1487611459768-bd414656ea10?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"

export const parseContentJson = (content) => {
  return draftToHtml(JSON.parse(content))
}
