import React from "react"

import { graphql, useStaticQuery } from "gatsby"

import PropTypes from "prop-types"

import DropdownList from "./DropdownList"

const ProductTypeList = ({ setProductTypeOpen }) => {
  const data = useStaticQuery(graphql`
    query allProductTypes {
      saleor {
        productTypes(first: 100) {
          edges {
            node {
              id
              slug
              name
            }
          }
        }
      }
    }
  `)

  const items = data.saleor.productTypes.edges.map(({ node }) => {
    return {
      name: node.name,
      link: `/products/type/${node.slug}`,
    }
  })

  const allProducts = {
    name: "All Products",
    link: "/products/",
  }

  return (
    <DropdownList
      items={[allProducts, ...items]}
      stateUpdateFunction={setProductTypeOpen}
    />
  )
}

ProductTypeList.propTypes = {
  setProductTypeOpen: PropTypes.func.isRequired,
}

export default ProductTypeList
